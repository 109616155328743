import { injectProperty } from '../config/inversify.config';
import { TYPES } from '../config/types';

import React, { Component } from 'react';

import { createStyles, Theme } from '@material-ui/core/styles';
import { WithStyles, withStyles } from '@material-ui/styles';


import { Home } from '../routes/Home/';
import { observer } from 'mobx-react';

import { HashRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router';


import theme from '../theme';
import { IAuthStore } from '../stores/AuthStore';
import {Navigator} from './Navigator';
import {Header} from './Header';
import { ParkingDataStore, IParkingDataStore } from '../stores/ParkingDataStore';
import { onReactionError } from 'mobx';
import ZoneInfoView from '../routes/ZoneInfoView';

const drawerWidth = 240;
const styles = (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        minHeight: '100vh',
      },
      drawer: {
        [theme.breakpoints.up('sm')]: {
          width: drawerWidth,
          flexShrink: 0,
        },
      },
      appContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      },
      mainContent: {
        flex: 1,
        padding: '70px 10px 0',
        background: '#eaeff1',
      }
    }
    );

export interface ILayoutProps extends WithStyles<typeof styles> {

}

interface ILayoutState {
  mobileOpen:boolean;
}
class Layout extends Component<ILayoutProps,ILayoutState> {

  @injectProperty(TYPES.AuthStore) authStore!: IAuthStore;
  @injectProperty(TYPES.ParkingDataStore) parkingDataStore!:IParkingDataStore;

  constructor(props:ILayoutProps ) {
    super(props);
    this.state = {mobileOpen:false};
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);

  }

  componentDidMount() {
    this.parkingDataStore.loadMyZones();
  }

  render() {
    const { classes } = this.props;
    return (

      <div className={classes.root}>

        <nav className={classes.drawer}>
          <Navigator handleDrawerToggle={this.handleDrawerToggle} mobileOpen={this.state.mobileOpen}/>
        </nav>
        <div className={classes.appContent}>
        <Header handleDrawerToggle={this.handleDrawerToggle}/>
        <main className={classes.mainContent}>
        <Router>
          <Switch>
            <Route exact path="/a/home" component={Home} />
            <Route exact path="/a/zone" component={ZoneInfoView} />
            <Route exact path="/a/zone/:zoneUUID" component={ZoneInfoView} />
          </Switch>
        </Router>
        </main>
        </div>

      </div>
    );
  };

  handleDrawerToggle(): void {
    this.setState({mobileOpen: !this.state.mobileOpen})
  }
}

const WrappedLayout = withStyles(styles)(Layout);
export { WrappedLayout as Layout}
