import * as React from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {Login} from '../components/Login';
import {IAuthStore} from '../stores/AuthStore';
import {injectProperty} from '../config/inversify.config';
import {TYPES} from '../config/types';
import { observer } from 'mobx-react';


@observer
export class ProtectedRoute extends Route {

    @injectProperty(TYPES.AuthStore) authStore!:IAuthStore;
    
    public render() {
        let redirectPath: string = '';
        

        //if (!this.authStore.authenticated) {
        //    const renderComponent = () => (<Redirect to={{pathname: "/Login",state: {referrer: this.props.location }}}/>);
        //    return <Route {...this.props} component={renderComponent} render={undefined}/>;
        //}
        if (!this.authStore.authenticated) {
            return <Route><Login/></Route>;
        }
        else {
            return <Route {...this.props}/>;
        }
    }
}