// Services
import { injectable,inject } from "inversify";
import { observable, action, computed } from 'mobx';
import {TYPES} from '../config/types';
import { UserProfile, DefaultApi } from '../remoteapi/generated/api'

export interface IAuthStore {
    authenticated:boolean;
    user?:UserProfile;
    loginErrorMessage?:string;
    loginError:boolean;
    userFirstName:string;
    
    login(email:string,password:string):void;
    checkAuth(): void;
    logout():void;
    initPasswordReset(email:string):void;
    completePasswordReset(token:string,newPassword:string):void;
    changePassword(newPassword:string):void;
}
@injectable()
export class AuthStore implements IAuthStore {
   
    @inject(TYPES.RemoteAPI) private api!:DefaultApi;

    @observable public authenticated : boolean = false;
    public user?: UserProfile;
    @observable public loginErrorMessage?: string;
    
    @computed get loginError():boolean {
        if(this.loginErrorMessage ==null) return true;
        return false;
    }
    @computed get userFirstName():string {
        if(this.user  && this.user.firstName) return this.user.firstName;
        return '';
    }
    @action.bound
    login(email:string,password:string):void {
       let promise =  this.api.login({email:email, password:password});
       promise.then(r=> {
            
            this.user = r.data.userProfile;
            if(!r.data.authenticated)
                this.loginErrorMessage = r.data.errorMessage;
            else
                this.loginErrorMessage=undefined;
            this.authenticated = r.data.authenticated;
       });
    }

    @action.bound
    checkAuth():void {
       let promise =  this.api.amIAuthenticated();

       promise.then(r=> {
          this.authenticated = r.data.authenticated;
          this.user = r.data.userProfile;
       });

    }

    @action.bound
    logout():void {
       let promise =  this.api.logout();

       promise.then(r=> {
          this.authenticated = false;
          this.user = undefined;
       });

    }

    async initPasswordReset(email: string) {
        await this.api.initResetPassword({email:email});
        this.authenticated = false;
        this.user = undefined;
    }
    async completePasswordReset(token: string, newPassword: string ) {
        await this.api.completeResetPassword({token:token,newPassword:newPassword});
        this.authenticated = false;
        this.user = undefined;
    }

    async changePassword(newPassword:string) {
       await this.api.changeMyPassword({newPassword:newPassword});

    }

}