import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import googleLogo from './btn_google_light_normal_ios.svg';
import { Container, Grid, Box } from '@material-ui/core';
import {  observer } from 'mobx-react';
import {IAuthStore} from "../../stores/AuthStore";
import {TYPES} from "../../config/types";
import {injectProperty} from "../../config/inversify.config";
import {Link} from 'react-router-dom';
 

export interface ILoginState {
    email: string,
    password: string
}


@observer
export class Login extends React.Component<any, ILoginState> {

    @injectProperty(TYPES.AuthStore) authStore!:IAuthStore;
    constructor(props:any, context: any) {
        super(props, context);
        this.state = {
            password: '',
            email: ''
           
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleLoginOnClick = this.handleLoginOnClick.bind(this);

    }

    handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ ...this.state, email: e.target.value });
    }

    handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ ...this.state, password: e.target.value });
    }

    handleLoginOnClick() {
        this.authStore.login(this.state.email,this.state.password);
    }

    handleLoginWithGoogle() {
        window.location.href="/oauth2/authorize/google?redirect_uri=/oauth2/redirect";
    }



    public render() {
        return (
            <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>
                    <Button variant="outlined" onClick={this.handleLoginWithGoogle}><img src={googleLogo} />Log in with Google</Button>
                </Grid>
               <Grid item><Container>or</Container></Grid>
               <Grid item>
                <FormControl fullWidth >
                    <FormHelperText error={this.authStore.loginError}>{this.authStore.loginErrorMessage}</FormHelperText>
                    <TextField name="email" label="E-mail" value={this.state.email} onChange={this.handleEmailChange} fullWidth={true} />
                    <TextField name="password" label="Password" type="password" autoComplete="current-password" value={this.state.password} onChange={this.handlePasswordChange}  fullWidth={true} />
                    <Button variant="outlined" onClick={this.handleLoginOnClick}>Sign In</Button>

                </FormControl>
                </Grid>
                <Grid item>
                    <Link to="/passwordResetInit">Forgot your password?</Link>
                </Grid>
            </Grid>
        );


    }



}

export default Login;

