import { injectProperty } from './config/inversify.config';
import { TYPES } from './config/types';

import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { Login } from './components/Login';
import { PasswordResetInit } from './components/PasswordResetInit';
import { PasswordResetComplete } from './components/PasswordResetComplete';
import { Layout } from './layout';
import { Home } from './routes/Home/';
import { observer } from 'mobx-react';

import { HashRouter as Router } from 'react-router-dom';
import { Route, Switch,Redirect } from 'react-router';
import { ProtectedRoute } from "./routes/ProtectedRoute";


import { IAuthStore } from './stores/AuthStore';
import { CssBaseline } from '@material-ui/core';


class App extends Component<any> {

  @injectProperty(TYPES.AuthStore) authStore!: IAuthStore;

  componentWillMount() {
    this.authStore.checkAuth();
  }
  render() {

    return (

      <div>
        <Router>
          <Switch>
          <Route exact path="/passwordResetInit" component={PasswordResetInit} />
            <Route exact path="/passwordResetComplete/:resetToken" component={PasswordResetComplete} />
            <Route exact path="/" render={() => <Redirect to="/a/home" />} />
            <Route exact path="/a" render={() => <Redirect to="/a/home" />} />
            <ProtectedRoute path="/a" component={Layout} />
            <Route exact path="/login" component={Login} />  
          </Switch>
        </Router>
      </div>
    );
  };
}

export default App;
