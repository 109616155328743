import * as React from 'react';


import { AppBar, Toolbar, Typography,Menu,MenuItem } from '@material-ui/core';

import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { WithStyles, withStyles } from '@material-ui/styles';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {IAuthStore} from "../stores/AuthStore";
import {TYPES} from "../config/types";
import {injectProperty} from "../config/inversify.config";

import theme from '../theme';

const drawerWidth = 240;


const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            marginLeft: drawerWidth,
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        grow: {
            flexGrow: 1,
        },
    }
    );
export interface HeaderProps extends WithStyles<typeof styles> {
    handleDrawerToggle(): void;
}
class Header extends React.Component<HeaderProps, any> {
   
    public render() {
        const { classes } = this.props;
        return (
            <AppBar position="fixed" className={classes.appBar}  >

                <Toolbar className={classes.toolbar} >
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        edge="start"
                        onClick={this.props.handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography >
                        Park To Give
                    </Typography>
                    <div className={classes.grow} />
                    <AccountButtonAndMenu/>
                    
                    
                </Toolbar>
            </AppBar>
        )
    }
}
interface IAccountButtonAndMenuState {
    anchorEl:HTMLElement|null;
}
class AccountButtonAndMenu extends React.Component<any, IAccountButtonAndMenuState> {

    @injectProperty(TYPES.AuthStore) authStore!:IAuthStore;
    constructor(props:any, context: any) {
        super(props, context);
        this.state = {anchorEl: null};
           
        this.handleMenuClose = this.handleMenuClose.bind(this);
        this.handleMenuOpen = this.handleMenuOpen.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
       
    };
    handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
        this.setState({anchorEl: event.currentTarget});
    }
    handleMenuClose() {
        this.setState({anchorEl: null});
    }
    handleLogout() {
        this.authStore.logout();
        this.handleMenuClose();
        window.location.hash = "#";
    }
    public render() {
        return (
            <React.Fragment>
             <Typography>{this.authStore.userFirstName}</Typography>
            <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls="menu"
            aria-haspopup="true"
            onClick={this.handleMenuOpen}
            color="inherit"
            >
                <AccountCircle />
            </IconButton>
            <Menu
                id="menu"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                open={this.state.anchorEl!==null}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
            </Menu>
            </React.Fragment>
        );
    }
}
const WrappedHeader = withStyles(styles)(Header);
export { WrappedHeader as Header }