import React, { Component, Fragment } from "react";
import {match} from 'react-router-dom';

import { injectProperty } from '../../config/inversify.config';
import { TYPES } from '../../config/types';
import { IParkingDataStore, ParkingDataStore } from '../../stores/ParkingDataStore';

import { observer } from "mobx-react";
import { Typography, AppBar, Tabs, Tab, Paper, Grid, Hidden, Table,TableCell,TableHead, TableRow, TableBody, Select } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from "material-table";
import { PaymentInfo, Peroid } from "../../remoteapi/generated/api";

interface ZoneInfoViewState {
  selectedTabIndex: number;
}

interface ZoneInfoViewProps {
  match?: match<{zoneUUID:string}>;
}

@observer
export class ZoneInfoView extends Component<ZoneInfoViewProps, ZoneInfoViewState> {

  @injectProperty(TYPES.ParkingDataStore) parkingDataStore!: IParkingDataStore;

  constructor(props: ZoneInfoViewProps) {
    super(props);
    this.state = { selectedTabIndex: 0 };
    this.handleTabChange = this.handleTabChange.bind(this);
  }
  componentDidUpdate(prevProps:ZoneInfoViewProps,prevState:ZoneInfoViewState) {
    if(this.props.match &&
        ((prevProps.match && (prevProps.match.params.zoneUUID != this.props.match.params.zoneUUID))
        || 
        (!prevProps.match))
    ) {
      this.parkingDataStore.selectZoneByUUID(this.props.match.params.zoneUUID);
    }
  }
   
  public render() {
    if(this.parkingDataStore.selectedZone)
    return ( 
      <div>
        <AppBar position="static">
          <Tabs value={this.state.selectedTabIndex} onChange={this.handleTabChange}>
            <Tab label="Zone Info" />
            <Tab label="Metrics" />
            <Tab label="Payments" />
          </Tabs>
        </AppBar>
        {this.state.selectedTabIndex === 0 && <ZoneInfo />}
        {this.state.selectedTabIndex === 1 && <ZoneMetrics />}
        {this.state.selectedTabIndex === 2 && <ZonePayments />}
      </div>
    );
    else return(<Typography>Loading</Typography>)
  };

  handleTabChange(event: React.ChangeEvent<{}>, value: number) {
    this.setState({ selectedTabIndex: value });
  }
}

class ZoneInfo extends Component {
  @injectProperty(TYPES.ParkingDataStore) parkingDataStore!: IParkingDataStore;
  public render() {
    let validPayments = this.parkingDataStore.selectedZoneValidPayments ? this.parkingDataStore.selectedZoneValidPayments : new Array<PaymentInfo>();
    return (
      <div>
        <Paper>
          <Grid container spacing={1}>
            <Grid item xs={3}> <Typography>Zone:</Typography></Grid>
            <Grid item xs={9}> <Typography>{this.parkingDataStore.selectedZone.zoneId}</Typography></Grid>

            <Grid item xs={3}> <Typography>Address:</Typography></Grid>
            <Grid item xs={9}>
              <Grid container spacing={0}>
                <Grid item xs={12}> <Typography>{this.parkingDataStore.selectedZone.locationStreetAddress}</Typography></Grid>
                <Grid item xs={12}> <Typography>{this.parkingDataStore.selectedZone.locationCity}, {this.parkingDataStore.selectedZone.locationState} {this.parkingDataStore.selectedZone.locationZip5}</Typography></Grid>
              </Grid>
            </Grid>

            <Grid item xs={3}> <Typography>Directions:</Typography></Grid>
            <Grid item xs={9}> <Typography>{this.parkingDataStore.selectedZone.directions}</Typography></Grid>

            <Grid item xs={3}> <Typography>Sponsor:</Typography></Grid>
            <Grid item xs={9}> <a href={this.parkingDataStore.selectedZone.sponsorWebsite}><Typography>{this.parkingDataStore.selectedZone.sponsor}</Typography></a></Grid>

            <Grid item xs={3}> <Typography>Reserved Times:</Typography></Grid>
            <Grid item xs={9}>
              <Grid container spacing={0}>
                {this.parkingDataStore.selectedZone.reservedScheduleDescr!.map((value, index) => {
                  return <Grid item xs={12}> <Typography>{value}</Typography></Grid>;
                })}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        
        <Paper>
          <Typography>Valid Parkers</Typography>
          <Table>
                <TableHead>
                    <TableRow>
                      <TableCell>TagNum</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Valid Till</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {validPayments.map(r => (
                      <TableRow>
                        <TableCell>{r.tagNum}</TableCell>
                        <TableCell>{r.paymentDateZoneLocal}</TableCell>
                        <TableCell>{r.reservedTillZoneLocal}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
          </Table>
        </Paper>
      </div>

    );
  }
}
@observer
class ZoneAnalysisAtAGlance extends Component {
  @injectProperty(TYPES.ParkingDataStore) parkingDataStore!: IParkingDataStore;
  public render() {
    if( !this.parkingDataStore.selectedZoneOverviewPaymentSummary) {
      if(this.parkingDataStore.loadingSelectedZoneOverviewPaymentSummary===false) 
        this.parkingDataStore.loadZoneOverviewPaymentSummary();
      return(<Fragment><CircularProgress/></Fragment>);
    }
   
    else return(
      <Table>
      <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Revenue($)</TableCell>
            <TableCell>#</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
              <TableCell>Today</TableCell>
              <TableCell>{this.parkingDataStore.selectedZoneOverviewPaymentSummary.todayPaidAmt}</TableCell>
              <TableCell>{this.parkingDataStore.selectedZoneOverviewPaymentSummary.todayPaidCnt}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Yesterday</TableCell>
              <TableCell>{this.parkingDataStore.selectedZoneOverviewPaymentSummary.yesterdayPaidAmt}</TableCell>
              <TableCell>{this.parkingDataStore.selectedZoneOverviewPaymentSummary.yesterdayPaidCnt}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last 7 Days</TableCell>
              <TableCell>{this.parkingDataStore.selectedZoneOverviewPaymentSummary.last7DaysPaidAmt}</TableCell>
              <TableCell>{this.parkingDataStore.selectedZoneOverviewPaymentSummary.last7DaysPaidCnt}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last 30 Days</TableCell>
              <TableCell>{this.parkingDataStore.selectedZoneOverviewPaymentSummary.last30DaysPaidAmt}</TableCell>
              <TableCell>{this.parkingDataStore.selectedZoneOverviewPaymentSummary.last30DaysPaidCnt}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 90 Days</TableCell>
              <TableCell>{this.parkingDataStore.selectedZoneOverviewPaymentSummary.last90DaysPaidAmt}</TableCell>
              <TableCell>{this.parkingDataStore.selectedZoneOverviewPaymentSummary.last90DaysPaidCnt}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last 365 Days</TableCell>
              <TableCell>{this.parkingDataStore.selectedZoneOverviewPaymentSummary.last365DaysPaidAmt}</TableCell>
              <TableCell>{this.parkingDataStore.selectedZoneOverviewPaymentSummary.last365DaysPaidCnt}</TableCell>
            </TableRow>

        </TableBody>    
    </Table>
    );
   
      

  }
}
class ZonePayments extends Component {
  @injectProperty(TYPES.ParkingDataStore) parkingDataStore!: IParkingDataStore;
  public render() {
    return (
      <div>
        <Paper>
          <Typography>Payments</Typography>
          <MaterialTable
            title="Payments"
            options={{search:false,pageSize:10,sorting:false}}
            columns={[
              { title: 'PaymentId', field: 'paymentId', hidden: true },
              { title: 'Date', field: 'paymentDateZoneLocal', emptyValue: '' },
              { title: 'Reserved Till(Zone Local)', field: 'reservedTillZoneLocal', emptyValue: '' },
              { title: 'Amount', field: 'paymentAmt', emptyValue: '' },
              { title: 'Tag', field: 'tagNum', emptyValue: '' },
            ]}
            detailPanel={[
              {
                render: rowData => {
                  return (
                    <Paper>
                      Payment Id:{rowData.paymentId}
                    </Paper>
                  )
                }
              }
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                let pagePromise = this.parkingDataStore.selectedZonePaymentProvider.getPage(query.page, query.pageSize);

                pagePromise.then(r => {
                  let pageData = r.data.map<PaymentTableData>((d) => {
                    return {
                      paymentId: d.paymentId!,
                      reservedTillZoneLocal: d.reservedTillZoneLocal!,
                      paymentDateZoneLocal: d.paymentDateZoneLocal!,
                      paymentAmt: d.paymentAmt!,
                      tagNum: d.tagNum!
                    }
                  })
                  resolve({
                    data: pageData,
                    page: r.page,
                    totalCount: r.totalCnt
                  });
                });

              })
            }

          />
        </Paper>
      </div>

    );
  }
}
class ZoneMetrics extends Component {
  public render() {
    return( 
      <Paper>
        <Typography>Zone Metrics</Typography>
        <ZoneAnalysisAtAGlance/>
        <ZoneSummary/>
    </Paper>)
  }
}
interface ZoneSummaryState {
  selectedPeroid:Peroid;
}
@observer
class ZoneSummary extends Component<any,ZoneSummaryState> {
  @injectProperty(TYPES.ParkingDataStore) parkingDataStore!: IParkingDataStore;
  public constructor(props:any) {
    super(props);
    this.state = { selectedPeroid:Peroid.DAILY};
    this.handlePeroidChange = this.handlePeroidChange.bind(this);
  }
  
  handlePeroidChange(event: React.ChangeEvent<{ name?: string; value: unknown }>):void {
    this.setState({selectedPeroid: event.target.value as Peroid });
    this.parkingDataStore.selectSummaryPeroid(event.target.value as Peroid);
  }
  
  public render() {
    if(!this.parkingDataStore.selectedZonePeroiodPaymentsSummary) {
      if(this.parkingDataStore.loadingselectedZonePeroiodPaymentsSummary===false) {
        this.parkingDataStore.selectSummaryPeroid(this.state.selectedPeroid);
      }
      return(<Fragment>
          <Typography>Zone Summary</Typography>
          <CircularProgress/>
        </Fragment>);
    }
    else return( 
        <Fragment>
        <Typography>Zone Summary</Typography>
        <Select native  value={this.state.selectedPeroid} onChange={this.handlePeroidChange}>
          <option value={Peroid.DAILY}>Daily</option>
          <option value={Peroid.MONTHLY}>Monthly</option>
          <option value={Peroid.QUARTERLY}>Quarterly</option>
          <option value={Peroid.YEARLY}>Yearly</option>
        </Select>
        <Table>
                <TableHead>
                    <TableRow>
                      <TableCell>Peroid</TableCell>
                      <TableCell>Revenue($)</TableCell>
                      <TableCell>#</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.parkingDataStore.selectedZonePeroiodPaymentsSummary.map(r => (
                      <TableRow>
                        <TableCell>{r.periodLabel}</TableCell>
                        <TableCell>{r.total}</TableCell>
                        <TableCell>{r.cnt}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
         </Table>
          </Fragment>
    )
  }
}
interface PaymentTableData {

  /**
   * 
   * @type {string}
   * @memberof PaymentInfo
   */
  paymentId: string;

  paymentAmt: number;


  paymentDateZoneLocal: string;


  reservedTillZoneLocal: string;

  tagNum: string;
}

export default ZoneInfoView;
