
import { Container, interfaces } from 'inversify';
import getDecorators from 'inversify-inject-decorators';
import 'reflect-metadata';

// Constants
import { TYPES } from './types';

import { AuthStore,IAuthStore } from '../stores/AuthStore';
import { ParkingDataStore,IParkingDataStore } from '../stores/ParkingDataStore';

//API
import {DefaultApiInterface,DefaultApi } from '../remoteapi/generated/api';
import {Configuration,ConfigurationParameters} from '../remoteapi/generated/configuration'

// InversifyJS setup
const container: Container = new Container();
const  decorators  = getDecorators(container);
const apiURL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "";

const configParameters : ConfigurationParameters = {
  basePath : apiURL + '/api/v1',
  baseOptions : {  
    validateStatus: function (status:number) {
      return (status >= 200 && status < 300) || status === 404; 
    },
    withCredentials: true
  }
}
const configuration : Configuration = new Configuration(configParameters);
configuration.baseOptions = configParameters.baseOptions;
const remoteApi = new DefaultApi(configuration,'/api/v1');
container.bind<DefaultApiInterface>(TYPES.RemoteAPI).toConstantValue(remoteApi);

container
  .bind<IAuthStore>(TYPES.AuthStore)
  .to(AuthStore)
  .inSingletonScope();
container.bind<IParkingDataStore>(TYPES.ParkingDataStore)
  .to(ParkingDataStore)
  .inSingletonScope();

// End Bindings
//export { container, lazyInject };

interface IBabelPropertyDescriptor extends PropertyDescriptor {
  initializer(): any;
}

const injectProperty = function(serviceIdentifier: interfaces.ServiceIdentifier<any>) {
  const original = decorators.lazyInject(serviceIdentifier);
  // the 'descriptor' parameter is actually always defined for class fields for Babel, but is considered undefined for TSC
  // so we just hack it with ?/! combination to avoid "TS1240: Unable to resolve signature of property decorator when called as an expression"
  return function(this: any, proto: any, key: string, descriptor?: IBabelPropertyDescriptor): void {
    // make it work as usual
    original.call(this, proto, key);
    // return link to proto, so own value wont be 'undefined' after component's creation
    descriptor!.initializer = function() {
      return proto[key];
    };
  };
};

export { container, injectProperty };

