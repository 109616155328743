import * as React from 'react';
import { match, Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { IAuthStore } from "../../stores/AuthStore";
import { TYPES } from "../../config/types";
import { injectProperty } from "../../config/inversify.config";

export interface IPasswordResetCompleteState {
    newPassword: string,
    confirmPassword: string,
    passwordValidationErrorMessage: string;
    passwordValidationError: boolean,
    submitted: boolean,
    formEnabled: boolean
}

interface PasswordResetCompleteProps {
    match: match<{ resetToken: string }>;
}

@observer
export class PasswordResetComplete extends React.Component<PasswordResetCompleteProps, IPasswordResetCompleteState> {

    @injectProperty(TYPES.AuthStore) authStore!: IAuthStore;
    constructor(props: PasswordResetCompleteProps, context: any) {
        super(props, context);
        this.state = {
            newPassword: '',
            confirmPassword: '',
            passwordValidationErrorMessage: '',
            passwordValidationError: false,
            submitted: false,
            formEnabled: true

        };

        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);

        this.handleOnClick = this.handleOnClick.bind(this);

    }

    handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ ...this.state, newPassword: e.target.value });
    }
    handleConfirmPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ ...this.state, confirmPassword: e.target.value });
    }
    static readonly SPECIAL_CHARS = "!\"#$%&‘()*+,-./:;<=>?@[\\]^{}|~_";
    validatePassword(): boolean {

        if (this.state.newPassword.length < 8) {
            this.setError("Password must be at least 8 characters long.");
            return false;
        }
        if (this.state.newPassword.search('[A-Z]') === -1) {
            this.setError("Password must contain at least one uppercase letter");
            return false;
        }
        if (this.state.newPassword.search('[0-9]') === -1) {
            this.setError("Password must contain at least one number");
            return false;
        }
        if (this.state.newPassword.search('[!\\"#$%&‘()*+,-./:;<=>?@\[\\\]^{}|~_]') === -1) {
            this.setError("Password must contain at least one of the following special characters " + PasswordResetComplete.SPECIAL_CHARS);
            return false;
        }
        if (this.state.newPassword != this.state.confirmPassword) {
            this.setError("New password and confirm password must be the same.");
            return false;
        }
        this.clearError();
        return true;
    }

    setError(message: string) {
        this.setState({ ...this.state, passwordValidationError: true, passwordValidationErrorMessage: message });
    }
    clearError() {
        if (this.state.passwordValidationError) {
            this.setState({ ...this.state, passwordValidationError: false, passwordValidationErrorMessage: '' });
        }
    }
    handleOnClick() {
        if (this.validatePassword()) {
            var token = this.props.match.params.resetToken;
            this.authStore.completePasswordReset(token, this.state.newPassword);
            this.setState({ ...this.state, submitted: true, formEnabled: false });
        }
    }

    public render() {
        return (
            <Grid container direction="column" justify="center" alignItems="center">
                {this.state.submitted &&
                    <Grid item>
                        <Typography>Your password has been changed. Click here to <Link to="/a/home">Login</Link> </Typography>

                    </Grid >
                }
                {!this.state.submitted &&
                    <React.Fragment>
                        <Grid item>
                            <Typography>Set a new password. Passwords must be
                                <ul>
                                    <li>At least 8 characters</li>
                                    <li>Have at least one captial letter</li>
                                    <li>Must contain at least one number</li>
                                    <li>Must contain at least one of the following {PasswordResetComplete.SPECIAL_CHARS}</li>
                                </ul>
                            </Typography>

                        </Grid >
                        <Grid item>
                            <FormControl disabled={!this.state.formEnabled}>
                                <FormHelperText error={this.state.passwordValidationError}>{this.state.passwordValidationErrorMessage}</FormHelperText>
                                <TextField name="newPassword" label="New Password" type="password" autoComplete="new-password" value={this.state.newPassword} onChange={this.handlePasswordChange} />
                                <TextField name="confirmPassword" type="password" autoComplete="new-password" label="Confirm Password" value={this.state.confirmPassword} onChange={this.handleConfirmPasswordChange} />
                                <Button variant="outlined"  disabled={!this.state.formEnabled} onClick={this.handleOnClick}>Reset Password</Button>
                            </FormControl>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        );


    }
}

export default PasswordResetComplete;

