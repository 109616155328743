import * as React from 'react';

import { IParkingDataStore } from "../stores/ParkingDataStore";
import { ZoneInfo } from "../remoteapi/generated/api";
import { TYPES } from "../config/types";
import { injectProperty } from "../config/inversify.config";
import { observer } from 'mobx-react';
import { AppBar, Toolbar, Typography, Link, Drawer, List, ListItem, ListItemText, Collapse, Hidden, MenuItem } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { WithStyles, withStyles } from '@material-ui/styles';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import theme from '../theme';
import { Redirect } from 'react-router'

const drawerWidth = 240;


const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            marginLeft: drawerWidth,
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        }
    }
    );

export interface NavigatorProps extends WithStyles<typeof styles> {
    handleDrawerToggle(): void,
    mobileOpen: boolean
}



class Navigator extends React.Component<NavigatorProps, any> {
    constructor(props: NavigatorProps) {
        super(props);
        this.drawerNavigation = this.drawerNavigation.bind(this);
    }
    public render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Hidden smUp>
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={this.props.mobileOpen}
                        onClose={this.props.handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <NavDrawerContents preNavigate={this.drawerNavigation} />
                    </Drawer>
                </Hidden>
                <Hidden xsDown>
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        <NavDrawerContents preNavigate={this.drawerNavigation} />
                    </Drawer>
                </Hidden>
            </React.Fragment>);
    }

    drawerNavigation(): void {
        this.props.handleDrawerToggle();
    }
}

interface INavDrawerContentsProps {
    preNavigate(): void;
}
interface INavDrawerContentsState {
    open: boolean;
    redirect?: string;
}
@observer
class NavDrawerContents extends React.Component<INavDrawerContentsProps, INavDrawerContentsState> {

    @injectProperty(TYPES.ParkingDataStore) parkingDataStore!: IParkingDataStore;

    public constructor(props: INavDrawerContentsProps, context: any) {
        super(props, context);
        this.state = { open: true, redirect: undefined };
        this.handleZoneCollapseClick = this.handleZoneCollapseClick.bind(this);
    }
    public render() {
        if (this.state.redirect) {
            let target = this.state.redirect;
            this.setState({ redirect: undefined });
            this.props.preNavigate();
            return (
                <Redirect to={target} />
            )
        }
        else if(this.parkingDataStore.availableZones) return (
            <List>
                <ListItem button onClick={this.handleZoneCollapseClick}>
                    <ListItemText primary="Zones" />
                    {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {this.parkingDataStore.availableZones.map(zi => {
                            return (
                                <MenuItem selected={this.parkingDataStore.selectedZone == zi}>
                                    <ListItem key={zi.zoneId} onClick={e => this.handleZoneClick(zi)}>
                                        <ListItemText primary={zi.zoneId} />
                                    </ListItem>
                                </MenuItem>
                            );
                        })

                        }
                    </List>
                </Collapse>
            </List>

        );
        else {
            return(<List></List>)
        }
        
    }

    handleZoneClick(zone: ZoneInfo): void {
        this.parkingDataStore.selectZone(zone);
        this.navigate('/a/zone/' + zone.zoneUUID)

    }
    navigate(to: string) {
        this.setState({ redirect: to })
    }
    renderZones() {

    }
    handleZoneCollapseClick(): void {
        this.setState({ ...this.state, open: !this.state.open });
    }
}

const WrappedNavigator = withStyles(styles)(Navigator);
export { WrappedNavigator as Navigator }