import React, {  Component } from "react";
import Container from '@material-ui/core/Container';
import {injectProperty } from '../../config/inversify.config';
import {TYPES} from '../../config/types';
import {IAuthStore} from '../../stores/AuthStore';
import { observer } from "mobx-react";
import { Typography } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Redirect } from "react-router";

@observer
export class Home extends Component {
    @injectProperty(TYPES.AuthStore) authStore!:IAuthStore;

    render() {
      return <Redirect to="/a/zone"/>
    };
  }
  
  export default Home;
  