import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import {  Grid, Typography } from '@material-ui/core';
import {  observer } from 'mobx-react';
import {IAuthStore} from "../../stores/AuthStore";
import {TYPES} from "../../config/types";
import {injectProperty} from "../../config/inversify.config";

export interface IPasswordResetInitState {
    email: string,
    submitted: boolean,
    formEnabled: boolean
}

export class PasswordResetInit extends React.Component<any, IPasswordResetInitState> {

    @injectProperty(TYPES.AuthStore) authStore!:IAuthStore;
    constructor(props:any, context: any) {
        super(props, context);
        this.state = {
            email: '',
            submitted: false,
            formEnabled: true
           
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
       
        this.handleOnClick = this.handleOnClick.bind(this);

    }

    handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ ...this.state, email: e.target.value });
    }

    
    handleOnClick() {
        this.authStore.initPasswordReset(this.state.email);
        this.setState({ ...this.state, submitted: true,formEnabled: false  });
    }

    public render() {
        return (
            <Grid container direction="column" justify="center" alignItems="center">
               { this.state.submitted &&
                <Grid item>
                    <Typography>Your reset has been submitted. Check your email for reset instructions.</Typography>
                </Grid >
                }
                { !this.state.submitted &&
                <Grid item>
                    <Typography>If you have forgotten your password you can reset it by entering you email address below.</Typography>
                </Grid >
                }
               <Grid item>
                    <FormControl disabled={!this.state.formEnabled}>
                        <TextField  name="email" label="E-mail" value={this.state.email} onChange={this.handleEmailChange} />
                        <Button  variant="outlined" disabled={!this.state.formEnabled} onClick={this.handleOnClick}>Reset Password</Button>
                    </FormControl>
                </Grid>
            </Grid>
        );


    }
}

export default PasswordResetInit;

