// Services
import { injectable,inject, interfaces } from "inversify";
import { observable, action, computed } from 'mobx';
import {TYPES} from '../config/types';
import { ZoneInfo, PaymentInfo, DefaultApi, Payment, OverviewPaymentsSummary, PeriodPaymentsSummary, Peroid } from '../remoteapi/generated/api'
import {AxiosPromise} from 'axios';
import { timingSafeEqual } from "crypto";

export interface IParkingDataStore {
    availableZones:Array<ZoneInfo>;
    selectedZone:ZoneInfo;
    selectedZonePaymentProvider:ZonePaymentDataProvider;
    selectedZoneValidPayments:Array<PaymentInfo>;
    selectedZoneOverviewPaymentSummary:OverviewPaymentsSummary|undefined;
    loadingSelectedZoneOverviewPaymentSummary:boolean;
    selectedZonePeroiodPaymentsSummary:Array<PeriodPaymentsSummary>|undefined;
    loadingselectedZonePeroiodPaymentsSummary:boolean;
    loadMyZones():void;
    loadZoneOverviewPaymentSummary():void;
    selectZone(zone:ZoneInfo):void;
    selectZoneByUUID(zoneUUID:string):void;
    selectSummaryPeroid(peroid:Peroid):void;
}
@injectable()
export class ParkingDataStore implements IParkingDataStore {
  
    @inject(TYPES.RemoteAPI) private api!:DefaultApi;

    @observable availableZones!:Array<ZoneInfo>;
    @observable selectedZone!:ZoneInfo;
    @observable selectedZoneValidPayments!:Array<PaymentInfo>;
    @observable selectedZonePaymentProvider!:ZonePaymentDataProvider;
    
    @observable selectedZoneOverviewPaymentSummary:OverviewPaymentsSummary|undefined;
    @observable loadingSelectedZoneOverviewPaymentSummary:boolean=false;
    
    @observable selectedZonePeroiodPaymentsSummary!:Array<PeriodPaymentsSummary>|undefined;
    @observable loadingselectedZonePeroiodPaymentsSummary:boolean=false;

    @observable initilized:boolean = false;
    
    
    @action.bound
    loadMyZones():void {
        let promise = this.api.getMyZoneInfo();
        promise.then(r => {
            this.availableZones=r.data;
            if(this.selectedZone==null && this.availableZones.length > 0)
                this.selectZone(this.availableZones[0]);
            this.initilized=true;
        });
    }

    @action.bound
    loadZoneOverviewPaymentSummary():void {
        this.loadingSelectedZoneOverviewPaymentSummary=true;
        this.api.getZoneOverviewPaymentsSummary(this.selectedZone.zoneUUID!).then(r=>{
            console.log(r.data);
            this.selectedZoneOverviewPaymentSummary = r.data;
            this.loadingSelectedZoneOverviewPaymentSummary=false;
        })
    }
    
    @action.bound
    selectZone(zone:ZoneInfo):void {
        
        this.selectedZone = zone;
        this.selectedZonePaymentProvider = new ZonePaymentDataProvider(this.api,zone);
        this.api.getZonePaymentInfo(zone.zoneUUID!,true,0,1000).then( r => {this.selectedZoneValidPayments = r.data});
        this.selectedZoneOverviewPaymentSummary=undefined;
        this.selectedZonePeroiodPaymentsSummary=undefined;

    }

    @action.bound
    selectZoneByUUID(zoneUUID:string):void {
        if(this.initilized) {
            let zone = this.availableZones.find((value:ZoneInfo)=> {return value.zoneUUID == zoneUUID});
            if(zone) this.selectZone(zone);
        }
        else {
            let zonePromise = this.api.getZoneInfoByUUID(zoneUUID);
            zonePromise.then(r => {
                this.selectZone(r.data);
            });
        }
        this.api.getZonePaymentInfo(zoneUUID,true).then( r => {this.selectedZoneValidPayments = r.data});
       

    }
    @action.bound
    selectSummaryPeroid(peroid:Peroid):void {
        this.loadingselectedZonePeroiodPaymentsSummary=true;
        this.api.getZonePaymentsSummaryByPeroid(this.selectedZone.zoneUUID!,peroid).then(r=> 
            {   this.selectedZonePeroiodPaymentsSummary = r.data;
                this.loadingselectedZonePeroiodPaymentsSummary=false;
            });
    }
    
    

}

export class ZonePaymentDataProvider {
    zone:ZoneInfo;
    payments:Array<PaymentInfo>;
    pageNum:number;
    cnt:number;
    paymentCntPromise:AxiosPromise<number>|undefined;
    private api:DefaultApi;

    constructor(api:DefaultApi,zone:ZoneInfo) {
        this.api = api;
        this.zone = zone;
        this.payments =  [];
        this.pageNum = 0;
        this.cnt = 0;
      

        this.paymentCntPromise = this.api.getZonePaymentInfoCount(zone.zoneUUID!,false);
        
        this.paymentCntPromise.then(cnt => {
            this.cnt = cnt.data;
        });
    }

    public async getPage(pageNum:number,pageSize:number):Promise<PaymentPageData> {
       
        let pageDataPromise = this.api.getZonePaymentInfo(this.zone.zoneUUID!,false,pageNum,pageSize);
        if(this.paymentCntPromise==undefined) {
            await this.paymentCntPromise;
            this.paymentCntPromise=undefined;
        }
        
        return new Promise<PaymentPageData>((resolve,reject) => {
            pageDataPromise.catch(r => {
                console.log("error load payment data");
                reject("error load payment data");
            })
            pageDataPromise.then(r => {
                resolve({
                    data:r.data,
                    page:pageNum,
                    totalCnt:this.cnt
                })
            });
        });
    } 


}
export interface PaymentPageData {
    data:Array<PaymentInfo>;
    page:number;
    totalCnt:number;
}


